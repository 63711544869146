import React, { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import { AnimatePresence, motion, Variants } from "framer-motion";
import { XMarkIcon } from "@heroicons/react/24/solid";

interface Meal {
  uuid: string;
  mealId: number;
  deg: boolean;
  isTopMeal: boolean;
  title: string;
  description: string | null;
  allergens: string | null;
  price: number;
  date: string;
  created: string;
  updated: string;
  stars?: number;
  customerDescription?: string;
  disabled: boolean;
  ordnungSauberkeitStars?: number;
  personalStars?: number;
}

const Star: React.FC<{
  selected: boolean;
  onClick: () => void;
  large?: boolean;
}> = ({ selected, onClick, large }) => {
  return (
    <div className={"p-1 cursor-pointer"} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={large ? "h-12 w-12" : "h-9 w-9"}
        fill={selected ? "#ffe06e" : "none"}
        viewBox="0 0 24 24"
        stroke="#ffe06e"
        strokeWidth="2"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
        />
      </svg>
    </div>
  );
};

const mealVariants: Variants = {
  open: {
    y: 0,
    transition: {
      stiffness: 400,
      damping: 100,
    },
  },
  closed: {
    y: "100%",
    transition: {
      stiffness: 400,
      damping: 100,
    },
  },
};

const successVariants: Variants = {
  open: {
    x: 0,
    transition: {
      stiffness: 400,
      damping: 100,
    },
  },
  closed: {
    x: "100%",
    transition: {
      stiffness: 400,
      damping: 100,
    },
  },
};

const App: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [kantine, setKantine] = useState<string>("");
  const [meals, setMeals] = useState<Meal[]>([]);
  const [canSubmit, setCanSubmit] = useState<boolean>(true);
  const [noParams, setNoParams] = useState<boolean>(true);
  const [selectedMeal, setSelectedMeal] = useState<Meal | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [display, setDisplay] = useState<string>("");

  useEffect(() => {
    const storage = localStorage.getItem("submits");
    try {
      if (storage) {
        const s: string[] = JSON.parse(storage);
        for (let i = 0; i < s.length; i++) {
          if (dayjs(s[i]).isSame(dayjs(), "day")) {
            setCanSubmit(false);
          }
        }
      }
    } catch {
      setCanSubmit(true);
      localStorage.removeItem("submits");
    }
  }, []);

  useEffect(() => {
    const display = searchParams.get("uuid");
    if (display) {
      setDisplay(display);
      axios.get<Meal[]>("/" + display + "/meals").then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].disabled = false;
        }
        setMeals(response.data);
      });
      axios.get("/" + display + "/kantine").then((response) => {
        setKantine(response.data);
      });
      setNoParams(false);
    } else {
      setNoParams(true);
    }
  }, [searchParams]);

  const handleStarClick = (index: number) => {
    if (!selectedMeal) return;
    const copy = { ...selectedMeal };
    if (typeof copy.stars === "undefined") {
      copy.stars = index;
    } else if (copy.stars === index) {
      copy.stars = undefined;
    } else {
      copy.stars = index;
    }
    setSelectedMeal(copy);
  };

  const handlePersonalStarClick = (index: number): void => {
    if (!selectedMeal) return;
    const copy = { ...selectedMeal };
    if (typeof copy.personalStars === "undefined") {
      copy.personalStars = index;
    } else if (copy.personalStars === index) {
      copy.personalStars = undefined;
    } else {
      copy.personalStars = index;
    }
    setSelectedMeal(copy);
  };

  const handleOrdnungStarClick = (index: number): void => {
    if (!selectedMeal) return;
    const copy = { ...selectedMeal };
    if (typeof copy.ordnungSauberkeitStars === "undefined") {
      copy.ordnungSauberkeitStars = index;
    } else if (copy.ordnungSauberkeitStars === index) {
      copy.ordnungSauberkeitStars = undefined;
    } else {
      copy.ordnungSauberkeitStars = index;
    }
    setSelectedMeal(copy);
  };

  const showSuccessMessage = () => {
    setSuccess(true);
    setTimeout(() => {
      setSuccess(false);
    }, 3000);
  };

  const submit = () => {
    if (!selectedMeal) return;
    const storage = localStorage.getItem("submits");
    if (storage) {
      try {
        const s: string[] = JSON.parse(storage);
        let i = s.length;
        while (i--) {
          if (dayjs().isSame(s[i], "day")) {
            showSuccessMessage();
            setCanSubmit(false);
            setSelectedMeal(null);
            return;
          }
        }
      } catch {
        showSuccessMessage();
        setCanSubmit(false);
        setSelectedMeal(null);
        return;
      }
    }
    if (!selectedMeal.stars) {
      return;
    }
    const url =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3333/bewertung/" + display
        : "https://api.gourmetta.de/bewertung/" + display;
    axios
      .post(url, selectedMeal)
      .then(() => {
        const storage = localStorage.getItem("submits");
        if (storage) {
          try {
            const s: string[] = JSON.parse(storage);
            s.push(dayjs().format("YYYY-MM-DD"));
            localStorage.setItem("submits", JSON.stringify(s));
          } catch {
            localStorage.removeItem("submits");
            const s = [dayjs().format("YYYY-MM-DD")];
            localStorage.setItem("submits", JSON.stringify(s));
          }
        } else {
          localStorage.setItem(
            "submits",
            JSON.stringify([dayjs().format("YYYY-MM-DD")])
          );
        }
        setCanSubmit(false);
        showSuccessMessage();
        setSelectedMeal(null);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!selectedMeal) return;
    const copy = { ...selectedMeal };
    copy.customerDescription = event.target.value;
    setSelectedMeal(copy);
  };

  const handleMealClick = (meal: Meal) => {
    if (!canSubmit) return;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (selectedMeal === null) {
      setSelectedMeal(meal);
      return;
    }
    if (selectedMeal.uuid === meal.uuid) {
      setSelectedMeal(null);
      return;
    }
    setSelectedMeal(meal);
  };

  return (
    <div className={"w-full flex flex-col bg-white relative z-0"}>
      <div
        className={"min-h-screen w-full h-full z-20 px-4"}
        style={{
          background: "rgba(255, 255, 255, 0.4)",
          backdropFilter: "blur(25px)",
        }}
      >
        {noParams && (
          <h1 className={"text-5xl font-bold"}>Keinen Standort gefunden</h1>
        )}
        {!noParams && (
          <div className={"flex flex-col gap-3 pb-24"}>
            <p className={"text-xl mt-12"}>
              Hallo liebe Gäste des/der
            </p>
            <h1 className={"text-5xl font-bold"}>{kantine}</h1>
            <p className={"text-xl"}>Gebt uns euer Feedback!</p>
            <p className={"text-sm"}>Wähle dein Menü, um es zu bewerten.</p>
            <h3 className={"font-bold text-xl mt-4"}>Die heutigen Menüs</h3>
            {meals.map((meal, index) => (
              <motion.div
                whileTap={{ scale: 0.97 }}
                style={{ opacity: canSubmit ? 1 : 0.6 }}
                className={
                  "p-4 will-change-transform border-4 border-gray-200 border-solid bg-white shadow-sm rounded-xl"
                }
                key={index}
                onClick={() => handleMealClick(meal)}
              >
                <div className={"flex flex-row w-full"}>
                  <div className={"grow"}>
                    <p className={"text-xl"}>{meal.title}</p>
                    <p className={"text-sm"}>{meal.description}</p>
                    <p className={"text-xs"}>{meal.allergens}</p>
                  </div>
                  <div className={"flex flex-col"}>
                    <p
                      className={
                        "rounded-full min-w-[80px] text-center bg-black text-white py-2 px-3 text-xs font-bold"
                      }
                    >
                      Menü {meal.mealId}
                    </p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        )}
      </div>
      <div
        className={
          "rounded-full w-40 h-40 bg-blue-600 fixed z-10 top-0 -right-1/4 animate-wiggle will-change-transform"
        }
      />
      <div
        className={
          "rounded-full w-80 h-80 bg-orange-400 fixed z-10 -bottom-10 -left-28 animate-wiggle will-change-transform delay-500"
        }
      />
      <AnimatePresence>
        {selectedMeal !== null && (
          <motion.div
            variants={mealVariants}
            initial={"closed"}
            animate={"open"}
            exit={"closed"}
            className={
              "z-40 shadow-xl bg-white w-full will-change-transform absolute top-0 left-0 right-0 bottom-0 flex flex-col"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <button
              className={
                "rounded-full fixed top-4 left-4 bg-gray-300 flex flex-row items-center p-2"
              }
              onClick={() => setSelectedMeal(null)}
            >
              <XMarkIcon className={"w-5 h-5 text-gray-700"} />
            </button>
            <div
              className={
                "bg-black fixed top-4 right-4 text-white rounded-full py-2 px-3 font-bold text-xs"
              }
            >
              Menü {selectedMeal.mealId}
            </div>
            <div className={"overflow-y-auto max-h-max p-4"}>
              <h3 className={"text-2xl font-bold mt-12"}>
                Menü: {selectedMeal.title}
              </h3>
              <h1 className={"text-5xl mt-6 font-bold text-center"}>
                Wie hat dir unser Menü geschmeckt?
              </h1>
              <div className={"flex flex-row gap-4 justify-center mt-4"}>
                <Star
                  large
                  selected={
                    typeof selectedMeal.stars !== "undefined" &&
                    selectedMeal.stars > 0
                  }
                  onClick={() => handleStarClick(1)}
                />
                <Star
                  large
                  selected={
                    typeof selectedMeal.stars !== "undefined" &&
                    selectedMeal.stars > 1
                  }
                  onClick={() => handleStarClick(2)}
                />
                <Star
                  large
                  selected={
                    typeof selectedMeal.stars !== "undefined" &&
                    selectedMeal.stars > 2
                  }
                  onClick={() => handleStarClick(3)}
                />
                <Star
                  large
                  selected={
                    typeof selectedMeal.stars !== "undefined" &&
                    selectedMeal.stars > 3
                  }
                  onClick={() => handleStarClick(4)}
                />
                <Star
                  large
                  selected={
                    typeof selectedMeal.stars !== "undefined" &&
                    selectedMeal.stars > 4
                  }
                  onClick={() => handleStarClick(5)}
                />
              </div>
              <h2 className={"text-2xl mt-6 font-bold text-center"}>
                Wie zufrieden warst du mit unseren Mitarbeitern?
              </h2>
              <div className={"flex flex-row gap-4 justify-center mt-4"}>
                <Star
                  selected={
                    typeof selectedMeal.personalStars !== "undefined" &&
                    selectedMeal.personalStars > 0
                  }
                  onClick={() => handlePersonalStarClick(1)}
                />
                <Star
                  selected={
                    typeof selectedMeal.personalStars !== "undefined" &&
                    selectedMeal.personalStars > 1
                  }
                  onClick={() => handlePersonalStarClick(2)}
                />
                <Star
                  selected={
                    typeof selectedMeal.personalStars !== "undefined" &&
                    selectedMeal.personalStars > 2
                  }
                  onClick={() => handlePersonalStarClick(3)}
                />
                <Star
                  selected={
                    typeof selectedMeal.personalStars !== "undefined" &&
                    selectedMeal.personalStars > 3
                  }
                  onClick={() => handlePersonalStarClick(4)}
                />
                <Star
                  selected={
                    typeof selectedMeal.personalStars !== "undefined" &&
                    selectedMeal.personalStars > 4
                  }
                  onClick={() => handlePersonalStarClick(5)}
                />
              </div>
              <h2 className={"text-2xl mt-6 font-bold text-center"}>
                Wie wurde auf Ordnung und Sauberkeit geachtet?
              </h2>
              <div className={"flex flex-row gap-4 justify-center mt-4"}>
                <Star
                  selected={
                    typeof selectedMeal.ordnungSauberkeitStars !==
                      "undefined" && selectedMeal.ordnungSauberkeitStars > 0
                  }
                  onClick={() => handleOrdnungStarClick(1)}
                />
                <Star
                  selected={
                    typeof selectedMeal.ordnungSauberkeitStars !==
                      "undefined" && selectedMeal.ordnungSauberkeitStars > 1
                  }
                  onClick={() => handleOrdnungStarClick(2)}
                />
                <Star
                  selected={
                    typeof selectedMeal.ordnungSauberkeitStars !==
                      "undefined" && selectedMeal.ordnungSauberkeitStars > 2
                  }
                  onClick={() => handleOrdnungStarClick(3)}
                />
                <Star
                  selected={
                    typeof selectedMeal.ordnungSauberkeitStars !==
                      "undefined" && selectedMeal.ordnungSauberkeitStars > 3
                  }
                  onClick={() => handleOrdnungStarClick(4)}
                />
                <Star
                  selected={
                    typeof selectedMeal.ordnungSauberkeitStars !==
                      "undefined" && selectedMeal.ordnungSauberkeitStars > 4
                  }
                  onClick={() => handleOrdnungStarClick(5)}
                />
              </div>
              <p className={"text-sm mt-4"}>
                Können wir noch etwas besser machen?
              </p>
              <textarea
                maxLength={500}
                className={
                  "rounded-xl w-full p-3 border-4 border-gray-200 mt-2"
                }
                onChange={handleDescriptionChange}
              />
              <button
                type={"submit"}
                className={
                  "rounded-full mt-4 bg-cyan-500 text-white py-3 w-full text-sm font-bold"
                }
                onClick={submit}
              >
                Bewertung absenden
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {success && (
          <motion.div
            variants={successVariants}
            initial={"closed"}
            animate={"open"}
            exit={"closed"}
            className={
              "fixed will-change-transform top-10 right-10 bg-white rounded-xl shadow-xl z-50 p-4 border-4 border-green-500 font-bold"
            }
          >
            Vielen Dank für deine Bewertung!
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default App;
