import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import axios from "axios";
import {BrowserRouter, Route, Routes} from "react-router-dom";

const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:3333/bewertung" : "https://api.gourmetta.de/bewertung";

axios.defaults.baseURL = baseURL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Routes>
      <Route path={""} element={<App />} />
    </Routes>
  </BrowserRouter>
);
